<template>
	<div
		class="kb-card"
		:class="[{
			'kb-card__nofooter': noFooter,
			'kb-card__webinar': cardType == 'webinar',
			'kb-card__webinar-nofooter': cardType == 'webinar' && noFooter
		}, 'card-type-' + cardType]">

		<div class="kb--image">
			<icon-law-papers v-if="cardType == 'rla'"/>
			<icon-plain-papers v-else-if="cardType == 'material'" />
			<img v-else-if="cardType == 'business_type' && cardInfo.icon != null" :src="cardInfo.icon" alt="Иконка вида деятельности">
			<icon-mud-cart v-else-if="cardType == 'business_type' && cardInfo.icon == null"/>
			<icon-question-and-answer v-else-if="cardType == 'faq'" />
			<icon-video-page v-else-if="cardType === 'webinar'"/>
			<icon-plain-papers v-else/>
		</div>

		<div class="kb--header">
			<h4
				v-if="cardType == 'business_type'"
				class="kb--heading"
			>
				<router-link :to="{name:'MaterialsByBusinessType', params: { business_type: cardInfo.id } }">
					{{ cardInfo.type || 'Название пропало :(' }}
				</router-link>
			</h4>

			<h4
				v-else-if="cardType == 'faq'"
				class="kb--heading"
			>
				<router-link :to="{name: 'Question', params: { question: cardInfo.id } }">
					{{ cardInfo.title || 'Название пропало :(' }}
				</router-link>
			</h4>

			<h4
				v-else-if="cardType == 'rla'"
				class="kb--heading"
			>
				<router-link :to="{name: 'Rla', params: { id: cardInfo.id } }">
					{{ cardInfo.title || 'Название пропало :(' }}
				</router-link>
			</h4>

			<template v-else-if="cardType == 'webinar'">
				<h4
					class="kb--heading"
				>
					<router-link :to="{name: 'Webinar', params: { id: cardInfo.id } }">
						{{ cardInfo.title || 'Название пропало :(' }}
					</router-link>
				</h4>
				<span class="kb-card--date">{{normalizeDate(cardInfo.started)}}</span>
			</template>

			<h4
				v-else
				class="kb--heading"
			>
				<a :href="link" target="__blank">
					{{ cardInfo.title || 'Название пропало :(' }}
				</a>
			</h4>
			<hr>
		</div>

		<div class="kb--footer">
			<span
				v-if="cardType == 'business_type' || counter"
				class="kb-card--counter"
			>
				Материалов: <strong class="kb--materials-count">{{cardInfo.materials.length}}</strong>
			</span>
			<span v-else-if="cardType == 'webinar'" class="kb-card--metrec">
				<dl class="wc--speakers dl__theme" aria-label="Список спикеров">
					<div class="speakers--description">
						<dt class="hl__blue">{{ cardInfo.organization ? cardInfo.organization : 'Организация не указана' }}</dt>
						<dd>{{ cardInfo.speakers ? cardInfo.speakers : 'Спикеры не определены' }}</dd>
					</div>
				</dl>
			</span>
			<span v-else class="kb-card--metrec">
				{{ cardInfo.type_id || cardInfo.type || cardInfo.type_name || 'Методическая рекомендация' }}
			</span>
			<span v-if="cardType == 'webinar'" class="kb-card--date">
				{{normalizeDate(cardInfo.started)}}
			</span>
			<span v-else-if="cardType != 'business_type'" class="kb-card--date">
				{{normalizeDate(cardInfo.created)}}
			</span>
		</div>
	</div>
</template>

<script>
import IconVideoPage from '@/assets/svg/IconVideoPage.vue';
import IconQuestionAndAnswer from '@/assets/svg/IconQuestionAndAnswer.vue';
import IconLawPapers from '@/assets/svg/IconLawPapers.vue';
import IconMudCart from '@/assets/svg/IconMudCart.vue';
import IconPlainPapers from '@/assets/svg/IconPlainPapers.vue';


export default {
	name:'KbCard',
	components: {
		IconVideoPage,
		IconQuestionAndAnswer,
		IconLawPapers,
		IconMudCart,
		IconPlainPapers
	},
	props: {
		noFooter: Boolean,
		counter: Boolean,
		cardInfo: {
			type: Object,
			required: true,
		},
		cardType: {
			type: String,
			default: null,
			validate: (v) => [
				'webinar',
				'rla',
				'material',
				'business_type',
				'faq',
				null
			].includes(v)
		}
	},
	computed: {
		link() {
			const api_url = process.env.VUE_APP_STATIC_STORAGE
			return this.cardInfo.link ? this.cardInfo.link.search('http') < 0 ? api_url + this.cardInfo.link : this.cardInfo.link : false
		},
	},
}
</script>
